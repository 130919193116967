import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Headings play a critical role in communicating the structure of a page. Heading levels range from `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` to `}<inlineCode parentName="p">{`<h6>`}</inlineCode>{`.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Avoid skipping heading levels. `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` should be followed by `}<inlineCode parentName="li">{`<h2>`}</inlineCode>{` and so on.`}</li>
      <li parentName="ul">{`Do not use heading elements solely for resizing text.`}</li>
      <li parentName="ul">{`Avoid setting more than one `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` per page. `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` should be reserved to describe the page as a whole, similar to a page `}<inlineCode parentName="li">{`<title>`}</inlineCode>{`. (Dialog headings are excepted from this rule.)`}</li>
    </ul>
    <h2>{`Why?`}</h2>
    <p>{`A correct heading structure is critical for enabling users to navigate quickly within a page. Headings are by far the most common navigation technique for screen reader users.
`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/screenreadersurvey9/#finding"
      }}>{`67.7% of surveyed screen reader users`}</a>{` responded that they are first likely to navigate via the headings when trying
to find information on a lengthy web page. A proper heading structure also allows sighted users to visually scan and find what they want on a page quickly.`}</p>
    <p>{`An improper heading structure can cause a confusing navigation experience.`}</p>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <p>{`In your designs, annotate the heading level. Properly labeled headings are one of the most important things a designer can do for developer handoff.`}</p>
    <h3>{`For engineers`}</h3>
    <p>{`As you're developing a page, use tools like `}<a parentName="p" {...{
        "href": "https://accessibilityinsights.io/docs/en/web/overview/"
      }}>{`Accessibility Insights`}</a>{` and `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/headingsmap/flbjommegcjonpdmenkdiocclhjacmbi/related?hl=en"
      }}>{`HeadingsMap`}</a>{`
to verify the heading structure. You may also inspect the DOM structure of your page using the browser inspector, but using an extension will surface heading levels more easily.`}</p>
    <h2>{`Examples`}</h2>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Caption mdxType="Caption">Do follow a clear heading hierarchy</Caption>
    <img src="https://user-images.githubusercontent.com/40274682/157987874-e415dcc6-887f-4d8d-ab8a-bcdfbdad5e77.png" alt="A GitHub discussions page with sequential, h1, h2, h3, and h4 headings" />
  </Do>
  <Dont mdxType="Dont">
    <Caption mdxType="Caption">
      Don't use multiple h1s, skip over heading hierarchy for visual effect, or forget to label sections of information
      with headings
    </Caption>
    <img src="https://user-images.githubusercontent.com/40274682/157987882-edb92d5a-1fd3-4ef6-b5bb-bd08851eacd8.png" alt="A GitHub discussion page with two h1 headings, which display an h3 before h2 headings" />
  </Dont>
    </DoDontContainer>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/techniques/semanticstructure/#headings"
        }}>{`WebAIM: Headings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
        }}>{`W3: Headings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@inkblotty/headings-for-the-visually-inclined-c537e87865f"
        }}>{`Medium: Headings for the visually inclined`}</a></li>
    </ul>
    <h3>{`Related WCAG guidelines and success criteria:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/info-and-relationships"
        }}>{`1.3.1: Info and Relationships`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      